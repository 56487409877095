import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function VulnerabilityForm() {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (event) => {

        const formData = new FormData(event.target);
        for (var [key, value] of formData.entries()) {
            console.log(key, value);
        }
        setSubmitted(true);
        event.preventDefault();
        return;
    }

    return (
        <div>
            <div className="enquiry-box d-flex flex-column justify-content-center align-items-center">
                <div className="success-message text-center" style={{ zIndex: submitted ? 2 : 0, display: submitted ? 'block' : 'none' }}>
                    <span className='form-reset' onClick={() => setSubmitted(false)}>
                        <img className="mb-5" src="../images/contacts/complete.svg" alt="" height="64px" width="64px" layout={"intrinsic"} /></span>
                    <h3>Submitted Successfully</h3>
                </div>

                <form method="post" action="/" onSubmit={handleSubmit} style={{ zIndex: submitted ? 0 : 2, display: submitted ? 'none' : 'block' }}>
                    <div className="row">
                        <div className="col-lg-6">
                            <label for="fullname">Full Name</label>
                            <input type="text" id="fullname" name="fullname" placeholder="Full Name" required />
                        </div>
                        <div className="col-lg-6">
                            <label for="email">Email Id</label>
                            <input type="email" id="email" name="email" placeholder="Eamil Id" required />
                        </div>

                        <div className="mb-4"></div>

                        <div className="col-lg-12 d-flex flex-column">
                        <label for="address">Issue</label>
                        <textarea type="text" id="issue" name="issue" placeholder='Enter issue' required />
                    </div>

                    <div className="send-btn mt-4">
                        <button type='submit'>Submit</button>
                    </div>

                    <div className="enquiry-terms mt-3">
                        <p>By continuing, you agree to accept our <Link to='/Privacy'><span style={{ color: "#0060BE", fontWeight: "500" }}> Privacy Policy </span></Link> and <Link to='/terms'><span style={{ color: "#0060BE", fontWeight: "500" }}>Terms of Service</span></Link></p>
                    </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
