import React from 'react';
import Footer from '../../Partials/Footer'
import Header from '../../Partials/Header'
import VulnerabilityForm from './VulnerabilityForm';

export default function Vulnerability() {
  return (
    <div>
      <Header  showNav={true} isWhite={false}/>
      
      <section className='vulnerability' style={{ backgroundImage: "url(/images/contacts/vulnerabilityhome.png)", backgroundSize: "cover", opacity: "5", height: "560px" , backgroundPosition: "center"}}>
        <div className="container">
            <div className="top-section">
                <div className="row">
                    <div className="col-sm-12 col-lg-6 d-flex flex-column justify-content-center">
                        <div className="product-topic">
                            <h4 data-aos="fade-up" data-aos-duration="60" data-aos-easing="linear">Security at NesGlobal</h4>
                        </div>
                        <div className="product-heading mt-4">
                            <h1 data-aos="fade-up" data-aos-duration="60" data-aos-easing="linear">Vulnerability Programme</h1>
                        </div>
                        <div className="product-paragraph mt-4">
                            <p data-aos="fade-up" data-aos-duration="40" data-aos-easing="linear">We’re working with the security community to keep NesGlobal and our customers safe. If you’ve discovered a security vulnerability, we appreciate you responsibly disclosing it to us.</p>
                        </div>
                    </div>
                    <div className="col-lg-1"></div>
                    <div className="col-sm-12 col-lg-5">
                      <VulnerabilityForm/>
                    </div>
                </div>
            </div>
        </div>
    </section>

      <Footer />
    </div>
  );
}
