// const sdk = require("api")("@searoutes-docs/v2.0#iaug1ql4skf1u4");

// sdk.auth("Z6hcePbWvdR7AwunNGP1C6ZWWXDJB99tFNhCEtU3");

export const getOptions = {
    mode: 'cors',
    headers : {
        "Accept" : "application/json",
        "x-api-key" : "Z6hcePbWvdR7AwunNGP1C6ZWWXDJB99tFNhCEtU3"
    }
}

// export const getLocodeByQuery = async (port_name) => {
    
//     // let query = encodeURI(port_name);
//     // let response = await fetch('https://api.searoutes.com/geocoding/v2/port/'+query, {mode:'cors', headers: headers});
//     // if(response.status == 200){
//     //     let body = await response.json();
//     //     return body.features;
//     // }
//     feature.map((item, index) => properties.push(item.properties));
//     return properties;
// };
